import React from 'react';

import { Heading, Image, SelfIncrementingNumber } from 'components';

import image from './assets/image1.jpg';

import styles from './styles.module.scss';

const STATS = [
  { label: 'Cities', value: 30 },
  { label: 'Speakers', value: 300 },
  { label: 'Videos Produced', value: 400 },
  { label: 'Themes', value: 15 }
];

const Statistics = () => (
  <div className={styles.container}>
    <div className={styles.left}>
      <div className={styles.content}>
        <Heading size="small" className={styles.heading}>
          Unparalleled Scale & Reach
        </Heading>
        <div className={styles.data}>
          {STATS.map(({ label, value }) => (
            <div className={styles.item}>
              <SelfIncrementingNumber
                suffix="+"
                number={value}
                className={styles.value}
              />
              <div className={styles.label}>{label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className={styles.right}>
      <Image width="100%" src={image} />
    </div>
  </div>
);

export default Statistics;
